var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"orange darken-4","dark":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',{attrs:{"href":"#company"},on:{"click":_vm.scrollByTab}},[_vm._v("Компания")]),_c('v-tab',{attrs:{"href":"#technology"},on:{"click":_vm.scrollByTab}},[_vm._v("Технологии")]),_c('v-tab',{attrs:{"href":"#contacts"},on:{"click":_vm.scrollByTab}},[_vm._v("Контакты")])],1)]},proxy:true}])},[_c('v-app-bar-nav-icon'),_c('v-img',{staticClass:"mx-2",attrs:{"src":require('./assets/introscan-light.svg'),"max-height":"60","max-width":"60","contain":""}}),_c('v-toolbar-title',[_vm._v("АО «ИнтроСкан Технолоджи»")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"href":_vm.$url_isist,"outlined":""}},[_vm._v(" Войти в систему "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-login")])],1)],1),_c('v-main',[_c('AboutVideo',{attrs:{"id":"video"}}),_c('AboutCompany',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersectComponent,
        options: _vm.intersectOptions
      }),expression:"{\n        handler: onIntersectComponent,\n        options: intersectOptions\n      }"}],attrs:{"id":"company"}}),_c('AboutTechnology',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersectComponent,
        options: _vm.intersectOptions
      }),expression:"{\n        handler: onIntersectComponent,\n        options: intersectOptions\n      }"}],attrs:{"id":"technology"}}),_c('AboutContacts',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersectComponent,
        options: _vm.intersectOptions
      }),expression:"{\n        handler: onIntersectComponent,\n        options: intersectOptions\n      }"}],attrs:{"id":"contacts"}})],1),_c('v-footer',[_c('small',[_vm._v("© "+_vm._s(new Date().getFullYear())+", АО «ИнтроСкан Технолоджи»")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }