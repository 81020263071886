<template>
  <v-app>
    <v-app-bar
      app
      color="orange darken-4"
      dark
    >
    <!-- shrink-on-scroll -->
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-img
        :src="require('./assets/introscan-light.svg')"
        class="mx-2" max-height="60" max-width="60" contain>
      </v-img>
      <v-toolbar-title>АО «ИнтроСкан Технолоджи»</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :href="$url_isist" class="ma-2 white--text" outlined>
        Войти в систему
        <v-icon right dark>mdi-login</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs v-model="currentTab" align-with-title>
          <v-tab href="#company" @click="scrollByTab">Компания</v-tab>
          <v-tab href="#technology" @click="scrollByTab">Технологии</v-tab>
          <v-tab href="#contacts" @click="scrollByTab">Контакты</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <AboutVideo id="video" />
      <AboutCompany id="company"
        v-intersect="{
          handler: onIntersectComponent,
          options: intersectOptions
        }"
      />
      <AboutTechnology id="technology"
        v-intersect="{
          handler: onIntersectComponent,
          options: intersectOptions
        }"
      />
      <AboutContacts id="contacts"
        v-intersect="{
          handler: onIntersectComponent,
          options: intersectOptions
        }"
      />
      <!-- <AboutTechnology id="technology" v-intersect="onIntersectComponent" />
      <AboutContacts id="contacts" v-intersect="onIntersectComponent" /> -->
    </v-main>
    <v-footer>
      <small>© {{ new Date().getFullYear() }}, АО «ИнтроСкан Технолоджи»</small>
    </v-footer>
  </v-app>
</template>

<script>
// import AboutVideo from './components/AboutVideo.vue';
// import AboutCompany from './components/AboutCompany.vue';
// import AboutContacts from './components/AboutContacts.vue';
// import AboutTechnology from './components/AboutTechnology.vue';

export default {
  name: 'App',

  components: {
    AboutVideo: () => import('./components/AboutVideo.vue'),
    AboutCompany: () => import('./components/AboutCompany.vue'),
    AboutContacts: () => import('./components/AboutContacts.vue'),
    AboutTechnology: () => import('./components/AboutTechnology.vue'),
  },

  data: () => ({
    isTabPressed: false,
    currentTab: null,
    tabs: [
      { id: 'company', title: 'Компания' },
      { id: 'technology', title: 'Технологии' },
      { id: 'contacts', title: 'Контакты' },
    ],
    intersectOptions: {
      threshold: [0.5, 1.0],
    },
  }),

  methods: {
    scrollByTab(event) {
      this.isTabPressed = true;
      this.$vuetify.goTo(event.srcElement.hash).then(() => {
        this.isTabPressed = false;
      });
    },
    onIntersectComponent(entries) {
      if (this.isTabPressed) return;
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // console.log(`${entry.target.id} ${entry.intersectionRatio} ${entry.isIntersecting}`);
          this.currentTab = entry.target.id;
        }
      });
    },
  },
};
</script>
